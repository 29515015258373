<template>
  <EntitySingle
    :config="config"
    :entity.sync="entity"
    :extra-validation="extraValidation"
    :extra-actions="extraActions"
    :edit-mode="editMode"
    @entityFetched="updateEntity"
    @entitySaved="setEntity"
  >
    <b-tabs
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
      vertical
      pills
    >
      <b-tab
        v-for="section in sections"
        :key="section.id"
        :disabled="fieldDisabled(section)"
      >
        <template #title>
          <span class="font-weight-bold">{{ section.title }}</span>
        </template>
        <EntityEditSection
          :module="config.module"
          :entity.sync="entity"
          :form-fields="getSectionFields(section.id)"
          allow-autofocus
        />
      </b-tab>
    </b-tabs>
  </EntitySingle>
</template>

<script>
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import { getUserRights } from '@/auth/utils'
import { translationUtils } from '@core/mixins/ui/translations'
import storeModule from './storeModule'

export default {
  components: {
    EntityEditSection,
    EntitySingle,
  },
  mixins: [translationUtils],
  data() {
    return {
      entity: {
        questionnaire: { id: 0 },
        fields: [],
        colSize: 3,
      },
      centerOptions: [],
      allFields: [],
      patientCodeRequired: true,
      patientCodeTitle: null,
    }
  },
  computed: {
    questionnaireId() {
      return this.$route.params.qid
    },
    isManager() {
      return getUserRights().includes('Questionnaire_Management_Questionnaire_Edit')
    },
    editMode() {
      return !this.entity.id || !this.entity.published || (!this.entity.finalized && this.isManager)
    },
    config() {
      return {
        store: storeModule,
        module: 'questionnaire-answers',
        endpoint: 'questionnaire-answers',
        route: 'submissions',
        title: {
          single: this.$t('Questionnaire Add'),
          plural: this.$t('My Entries'),
        },
      }
    },
    extraActions() {
      if (this.entity.id) {
        return [
          {
            callback: this.printAnswer,
            icon: 'PrinterIcon',
            title: 'Print',
            variant: 'secondary',
            renderCondition: {
              key: 'published',
              value: true,
            },
          },
          {
            endpoint: 'publish',
            icon: 'SendIcon',
            title: 'Publish',
            validate: true,
            renderCondition: {
              key: 'published',
              value: false,
            },
            propertyNotExists: 'finalized',
          },
          {
            endpoint: 'finalize',
            icon: 'CheckCircleIcon',
            title: 'Finalize',
            variant: 'success',
            validate: true,
            renderCondition: {
              key: 'published',
              value: true,
            },
            authorityValidation: this.isManager
          },
        ]
      }
      return []
    },
    selectMultipleIds() {
      return (
        this.allFields
          .filter(field => field.type === 'SELECTMULTIPLE')
          .map(field => field.id) || []
      )
    },
    fields() {
      return [
        {
          id: 'patientInitials',
          type: 'text',
          title: this.$t('patientInitials'),
          required: true,
          size: this.colSize,
          helpText: this.$t(
            '3 first letters for surname, 3 first letters for name',
          ),
        },
        {
          id: 'patientCode',
          type: 'text',
          title: this.patientCodeTitle || this.$t('patientCode'),
          required: this.patientCodeRequired,
          size: this.colSize,
        },
        {
          id: 'center',
          type: 'select',
          object: true,
          title: this.$t('Center'),
          options: this.centerOptions,
          size: this.colSize,
        },
        {
          id: 'highRiskPatient',
          type: 'radio',
          title: this.$t('highRiskPatient'),
          options: ['Yes', 'No'],
          size: this.colSize,
        },
        {
          id: 'comments',
          type: 'textarea',
          title: this.$t('comments'),
          size: 12,
        },
      ].map(field => ({ ...field, ...{ section: { title: this.$t('General info') } } }))
    },
    sections() {
      return [
        ...new Set(this.allFields.map(item => JSON.stringify(item.section))),
      ].map(i => JSON.parse(i))
    }
  },
  async mounted() {
    if (this.questionnaireId) {
      this.entity.questionnaire.id = this.questionnaireId
      this.fetchFields(this.questionnaireId)
    }
  },
  methods: {
    async fetchFields(qId) {
      this.centerOptions = (await this.$store.dispatch('app-common/fetchAssignedQuestionnaireCenters', { id: this.entity.questionnaire.id })).data
      const response = await this.$store.dispatch('app-common/fetchQuestionnaireFields', qId)
      this.patientCodeRequired = response.data.patientCodeRequired
      this.patientCodeTitle = response.data.patientCodeTitle
      let fields = this.fields
      if (!response.data.patientInitialsSupported || !response.data.patientCodeSupported || !response.data.patientCodeSupported) {
        this.colSize = 4
      }
      if (!response.data.patientInitialsSupported) {
        fields = fields.filter(field => field.id != 'patientInitials')
      }
      if (!response.data.highRiskPatientSupported) {
        fields = fields.filter(field => field.id != 'highRiskPatient')
      }
      if (!response.data.patientCodeSupported) {
        fields = fields.filter(field => field.id != 'patientCode')
      }
      this.allFields = [...fields, ...response.data.fields]
    },
    fieldDisabled(field) {
      if (field.dependentField) {
        const val = field.dependentFieldValue
        const entityVal = this.entity[field.dependentField.id]
        const isMultiple = Array.isArray(entityVal)
        if (val.includes('!')) {
          if (isMultiple) {
            return !(entityVal && !entityVal.includes(val.replace('!', '')))
          }
          return entityVal == val.replace('!', '')
        } else if (val.includes(',')) {
          const arr = val.split(',')
          if (isMultiple) {
            return !(entityVal && entityVal.some(r => arr.includes(r)))
          }
          return !arr.includes(entityVal)
        }
        if (isMultiple) {
          return !(entityVal && entityVal.includes(val))
        }
        return entityVal != val
      }
      return false
    },
    getSectionFields(sectionId) {
      return this.allFields
        .filter(item => item.section.id === sectionId)
        .map(field => {
          let type = field.type.toLowerCase()
          let taggable = false
          let disabled = false
          let readonly = !this.editMode
          let autocompleteUrl = ''
          if (field.type == 'VIRTUAL') {
            type = 'text'
            readonly = true
            disabled = true
          } else if (field.type == 'AUTOCOMPLETE') {
            type = 'select'
            taggable = true
            autocompleteUrl = `questionnaire-answers/field-history/${field.id}`
          } else if (field.hideable && this.entity.id) {
            type = 'password'
          }
          return {
            id: field.id,
            type,
            object: field.object,
            options: field.options || [],
            label: field.title,
            suffix: field.unit ? field.unit.title : null,
            extraClass: field.required ? 'required-field' : null,
            readonly,
            taggable,
            autocompleteUrl,
            pushTags: taggable,
            disabled,
            colSize: field.size,
            helpText: field.helpText,
            hideToggle: !this.isManager,
            hidden: this.fieldDisabled(field),
            step: type == 'number' ? 'any' : null,
            rules:
              (field.minValue ? 'min_value:' + field.minValue : '') +
              (field.minValue && field.maxValue ? '|' : '') +
              (field.maxValue ? 'max_value:' + field.maxValue : ''),
            autoupload: true,
          }
        })
    },
    async updateEntity(entity) {
      if (!this.questionnaireId) {
        await this.fetchFields(this.entity.questionnaire.id)
        if (entity) {
          this.setEntity(entity)
        }
      }
    },
    setEntity(entity) {
      entity.fields.forEach(item => {
        const val = this.selectMultipleIds.includes(parseInt(item.field.id))
          ? item.value.split(',')
          : item.value
        this.entity[item.field.id] = val
      })
      this.entity.fields = []
    },
    extraValidation(action) {
      this.entity.fields = []
      Object.keys(this.entity).forEach(key => {
        if (!isNaN(key)) {
          const val = this.selectMultipleIds.includes(parseInt(key))
            ? this.entity[key].join()
            : this.entity[key]

          this.entity.fields.push({
            field: { id: key },
            value: val,
          })
          // delete this.entity[key]
        }
      })
      if (action?.endpoint == 'publish' && !this.entity.fields.length) {
        return this.$t('MISSING_EXTRA_RECORDING_FIELDS')
      }
      return ''
    },
    printAnswer() {
      this.$router.push({ name: 'answers-print', params: { qid: this.entity.questionnaire.id, id: this.entity.id } })
    },
  },
}
</script>
